import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RS = () => {
  return (
    <div style={{display:'flex',position:'fixed',right:10,bottom:10, flexDirection:'column'}}>
      
          <a className="pb-3" target="blank" href="https://wa.me/56989295165">
            <Button className="btn-just-icon" color="whatsapp">
              <FontAwesomeIcon
                icon={["fab", "whatsapp"]}
                size="3x"
                color="white"
              />
            </Button>
          </a>
       
            <a target="blank" href="https://waze.com/ul?ll=-40.210800,-73.03931&amp;navigate=yes">
            <Button className="btn-just-icon" color="waze">
              <FontAwesomeIcon icon={["fab", "waze"]} size="3x" color="white" />
            </Button>
          </a>
       
    </div>
  );
};
export default RS;
