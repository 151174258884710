import React from 'react'
import  { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
function Atractivos(){
    return(
        <div id="atractivos" className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title text-center">Atractivos</h2>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-plain card-blog text-center">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/atractivos/valdivia.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    {/* <h6 className="card-category text-warning">Travel</h6> */}
                    <CardTitle tag="h3">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        VALDIVIA
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      Su extraordinaria belleza natural, compuesta por majestuosos
                      ríos navegables y verdes e inhóspitos bosques, convierten a Valdivia
                      en una de las ciudades más hermosas e inigualables de todo Chile.
                      Puedes visitar El Jardín Botánico, El Santuario de la Naturaleza Carlos
                      Anwandter, La Reserva Costera Valdiviana.
                    </p>
                    <br />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-plain card-blog text-center">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/atractivos/kunstmann.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    {/* <h6 className="card-category text-info">Travel</h6> */}
                    <CardTitle tag="h3">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        NIEBLA
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      A escasos 17 kilómetros de Valdivia, la localidad costera de
                      Niebla es el balneario principal de los habitantes de esta ciudad. Se
                      accede a través de la ruta T-350, un hermoso camino rodeado de
                      vegetación pura y aguas azules de los ríos que confluyen en esta
                      zona. En este trayecto, podrás cruzar el Puente Cruces, que conecta
                      la Isla Teja con el sector de Torobayo, donde se encuentra la famosa
                      Cervecería Kunstmann.
                    </p>
                    <br />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-plain card-blog text-center">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/atractivos/parqueoncol.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    {/* <h6 className="card-category text-danger">Lifestyle</h6> */}
                    <CardTitle tag="h3">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        ONCOL
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      El Parque Oncol es una reserva forestal ubicada a 32
                      kilómetros de Valdivia. En este hermoso parque, podrás encontrar
                      áreas para acampar, hacer picnic y practicar deportes como trekking y
                      canopy; además tiene cuatro miradores con perfectas vistas para que
                      puedas contemplar el mar, la Cordillera de la Costa, el valle central y
                      los volcanes Llaima, Osorno y Tronador al otro lado de la cordillera.
                    </p>
                    <br />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-plain card-blog text-center">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/atractivos/bahiacoique.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    {/* <h6 className="card-category text-success">Best Seller</h6> */}
                    <CardTitle tag="h3">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        LAGO RANCO
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      A x km nos encontramos con el tercer lago mas grande
                      de Chile. Entre las zonas más destacadas que puedes visitar, está la
                      Bahía Coique, una hermosa playa de arenas grises a no más de
                      cuatro kilómetros de distancia de Futrono. En este lugar podrás
                      encontrar zonas de camping, cabañas, hostales, restaurantes,
                      quinchos y podrás practicar diversos deportes náuticos.
                    </p>
                    <br />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    )
}
export default Atractivos